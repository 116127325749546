<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    hide-default-footer
    :items-per-page="10000"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.date | utc_to_local_datetime("DD-MM-YYYY") }}</td>
          <td>{{ item.invoice_key }}</td>
          <td class="text-right">DKK {{ item.total | local_numbers }}</td>
          <td>
            {{ item.status }}
          </td>
          <td>
            {{ item.type === "INVOICE" ? "Invoice" : "Credit note" }}
          </td>
          <td class="word-no-wrap">
            <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  outlined
                  color="primary"
                  target="_blank"
                  :href="item.download_url"
                >
                  <v-icon small>fal fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>

            <v-tooltip v-if="item.type === 'INVOICE'" :disabled="$vuetify.breakpoint.xsOnly" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="ml-1"
                  icon
                  outlined
                  color="primary"
                  target="_blank"
                  :href="item.view_url"
                >
                  <v-icon small>fal fa-file-invoice-dollar</v-icon>
                </v-btn>
              </template>
              <span>Payment link</span>
            </v-tooltip>

            <v-tooltip v-if="item.type === 'INVOICE'" :disabled="$vuetify.breakpoint.xsOnly" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="ml-1"
                  icon
                  outlined
                  color="primary"
                  :to="{
                    name: 'report.sales',
                    query: {
                      invoice_nr: item.invoice_key,
                      start_date: '2018-01-01',
                      end_date: '2030-12-31'
                    }
                  }"
                >
                  <v-icon small>fal fa-file-search</v-icon>
                </v-btn>
              </template>
              <span>See sales</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>

    <div class="text-center" slot="no-data">
      No results
    </div>
  </v-data-table>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: "Date", sortable: false },
      { text: "id", sortable: false },
      { text: "Amount", sortable: false, align: "right" },
      { text: "Status", sortable: false },
      { text: "Type", sortable: false },
      { text: "", sortable: false }
    ]
  })
};
</script>
